import React, { Component } from 'react';

import './web-form.sass';
import socketIOClient from 'socket.io-client';

import Loader from 'react-spinners/BounceLoader';
import SurveySubmitted from './SurveySubmitted';
import { Navigate } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import { getNestedObjectSafely } from '../utils/functions';
import { getQuestionData, fetchBusinessData } from './web-form.service';
import NpsQuestion from './npsQuestion';
import RangeQuestion from './RangeQuestion';
import OpenEnded from './openEnded';
import SingleSelect from './singleSelect';
import MultipleSelect from './multipleSelect';
import FallBack from './fallback';
import CSAT from './CSAT';
import ResolutionScore from './ResolutionScore';
import IntegerChoice from './IntegerChoice';
import logo from './assets/ajua_logo.png';

class WebForm extends Component {
  constructor(props) {
    super(props);
    this.messageList = React.createRef();
    this.onSubmitFeedback = this.onSubmitFeedback.bind(this);
    this.onCaptchaVerify = this.onCaptchaVerify.bind(this);
    this.reloadPageWithLimit = this.reloadPageWithLimit.bind(this);

    let isInvalid = false;
    this.state = {
      currentQuestions: [
        {
          questionID: 0,
          questionType: 'ERROR_TYPE',
          questionText: 'Technical Error! Try again later',
          answer: true,
          answerList: ['YES', 'NO', 'STOP'],
          questionLevel: '1',
        },
      ],
      loading: true,
      surveyAnswer: [],
      isCaptchaValid: true,
      captchaLoaded: true,
      errorMessage: '',
      showError: false,
      isInvalid,
      surveyCompleted: false,
      accountId: null,
      accountLogo: null,
      surveyTitle: '',
      progress: 0,
      questionGroups: [],
      currentQuestionGroup: 0,
      surveyCompleteMessage: 'Thank you for your Participation',
      allQuestions: {},
    };
  }

  componentDidMount() {
    const { isInvalid } = this.state;
    if (!isInvalid) {
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);

      socket.on('connect', () => {
        console.log('connected');
        // only fetch after socket connection
        this.fetchFirstQuestion();
      });
      socket.on('newQuestion', (data) => {
        console.log('new: ', data);
        this.triggerNextQuestion(data);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Remove the zendesk widget on webforms
    const elem = document.querySelector('#launcher');
    if (elem != null) if (elem.style.display !== 'none') elem.style.display = 'none';
  }

  onCaptchaLoaded = () => {
    this.setState({ captchaLoaded: true });
  };

  onCaptchaVerify = (response) => {
    if (response) {
      this.setState({
        isCaptchaValid: true,
      });
    }
  };

  reloadPageWithLimit = () => {
    const maxReloads = 3;

    let reloadCount = parseInt(localStorage.getItem('reloadCount') ?? '0') || 0;

    if (reloadCount < maxReloads) {
      localStorage.setItem('reloadCount', String(reloadCount + 1));

      window.location.reload();
    } else {
      console.warn("Reload limit reached. The page won't be reloaded again.");
      this.setState({ errorMessage: 'Something went wrong', showError: true });
      setTimeout(() => {
        this.setState({ errorMessage: '', showError: false });
      }, 10000);
    }
  };

  onQuestionValueChanged = (responseText, questionID, questionLevel) => {
    const { surveyAnswer } = this.state;
    const objectIndex = surveyAnswer.findIndex(
      (question) => Number(question?.questionID) === Number(questionID),
    );
    const newSurveyAnswer = surveyAnswer;

    if (objectIndex === -1) {
      // error
      // this.setState({ errorMessage: 'Something went wrong, reloading the page', showError: true });

      this.reloadPageWithLimit();
      // setTimeout(() => {
      //   this.setState({ errorMessage: '', showError: false });
      // }, 10000);
      // this.setState({ errorMessage: '', showError: false });
      return;
    }
    newSurveyAnswer[objectIndex] = {
      responseText,
      questionID,
      questionLevel,
      isFinal: newSurveyAnswer[objectIndex].isFinal,
      required: newSurveyAnswer[objectIndex].required,
      questionType: newSurveyAnswer[objectIndex].questionType,
    };

    this.setState({ surveyAnswer: newSurveyAnswer }, () => {
      this.calculateProgress();
    });
  };

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  validatePhone = (phone) => {
    const matchPhone = phone.match(/^[0-9-+\s]+$/);
    if (matchPhone) if (phone.length >= 9 && phone.length <= 14) return true;
    return false;
  };

  validateQuestionAnswer = (answer) => {
    const { questionGroups } = this.state;

    let answersReady = true;
    let answerValue = answer?.responseText?.value;
    // check integer choice answer
    if (answer?.questionType === 'OPEN_ENDED_INTEGER') {
      // get question
      let currQuestion = null;
      questionGroups.forEach((questionGroup) => {
        if (currQuestion === null)
          currQuestion = questionGroup.find((question) => {
            return question.questionID === answer.questionID;
          });
      });
      console.log(currQuestion);
      let lowerLimit = 0;
      let upperLimit = 2047483647;
      if (currQuestion?.validationRule != null) {
        const validationValue = currQuestion.validationRule;
        const arr = validationValue.split('-');
        lowerLimit = parseInt(arr[0]);
        upperLimit = parseInt(arr[1]);
      }
      if (Number(answerValue) < lowerLimit || Number(answerValue > upperLimit)) {
        answersReady = false;
        this.setState({
          loading: false,
          errorMessage: 'Please give answers within the specified ranges',
          showError: true,
        });
        setTimeout(() => {
          this.setState({ errorMessage: '', showError: false });
        }, 8000);
      }
    }

    // open ended validation
    if (answer?.questionType === 'OPEN_ENDED') {
      // get question
      let currQuestion = null;
      questionGroups.forEach((questionGroup) => {
        if (currQuestion === null)
          currQuestion = questionGroup.find((question) => {
            return question.questionID === answer.questionID;
          });
      });
      let passedValidation = true;
      if (currQuestion?.inputType === 'EMAIL') {
        if (!this.validateEmail(answerValue)) {
          passedValidation = false;
          currQuestion.error = true;
          currQuestion.errorMessage = 'Enter a valid email';
        } else {
          delete currQuestion.error;
          delete currQuestion.errorMessage;
        }
      } else if (currQuestion?.inputType === 'PHONE') {
        if (!this.validatePhone(answerValue)) {
          passedValidation = false;
          currQuestion.error = true;
          currQuestion.errorMessage = 'Enter a valid phone number (eg 0721...)';
        } else {
          delete currQuestion.error;
          delete currQuestion.errorMessage;
        }
      } else if (currQuestion?.inputType === 'EMAIL_PHONE') {
        if (this.validatePhone(answerValue) || this.validateEmail(answerValue)) {
          delete currQuestion.error;
          delete currQuestion.errorMessage;
        } else {
          passedValidation = false;
          currQuestion.error = true;
          currQuestion.errorMessage = 'Enter a valid phone number (eg 0721...) or Email';
        }
      }

      if (!passedValidation) {
        answersReady = false;
        this.setState({
          loading: false,
          errorMessage: 'Some questions have not been properly answered',
          showError: true,
        });
        setTimeout(() => {
          this.setState({ errorMessage: '', showError: false });
        }, 8000);
      }
    }

    if (answer?.required === true && answer?.responseText?.text?.length < 1) {
      if (
        answer?.questionType !== 'MESSAGE_END' &&
        answer?.questionType !== 'ERROR_TYPE' &&
        answer?.questionType !== 'END_MESSAGE' &&
        answer?.questionType !== 'INTRO_MESSAGE_MESSAGE' &&
        answer?.questionType !== 'MESSAGE'
      ) {
        answersReady = false;
        this.setState({
          loading: false,
          errorMessage: 'Please give an answer for all questions marked with *',
          showError: true,
        });
        setTimeout(() => {
          this.setState({ errorMessage: '', showError: false });
        }, 8000);
      }
    }

    return answersReady;
  };

  /**
   * Submits the current question's answer and saves progress
   * @return {Promise<void>}
   */
  async onSubmitFeedback() {
    const { surveyAnswer, questionGroups, currentQuestionGroup } = this.state;

    const { commId, joinCode } = this.props;

    const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    let newUrl = '';

    // grouped questions logic
    if (currentQuestionGroup < questionGroups.length - 1) {
      // check if all current questions are answered

      const newIndex = currentQuestionGroup + 1;
      let answersReady = true;
      const neededAnswerQuestions = questionGroups[currentQuestionGroup].map((q) => q.questionID);
      surveyAnswer.forEach((answer) => {
        if (neededAnswerQuestions.includes(answer.questionID)) {
          if (answersReady) answersReady = this.validateQuestionAnswer(answer);
        }
      });

      if (answersReady === false) return;
      this.setState(
        {
          currentQuestionGroup: newIndex,
          currentQuestions: questionGroups[newIndex],
        },
        () => this.calculateProgress(),
      );
      window.scrollTo(0, 0);
    } else if (surveyAnswer.length > 0) {
      // fully answered section logic

      let answersReady = true;
      // check if all questions are answered
      surveyAnswer.forEach((answer) => {
        if (answersReady) answersReady = this.validateQuestionAnswer(answer);
      });

      if (answersReady === false) return;

      this.setState({ loading: true });

      try {
        const questionBody = {
          commId,
          surveyJoinCode: joinCode,
          responseList: surveyAnswer.map((obj) => ({
            ...obj,
            responseText: {
              text: obj.responseText.text.toString(),
              value: obj.responseText.value.toString(),
            },
          })),
        };

        this.setState({
          currentQuestionGroup: 0,
          currentQuestions: [],
          progress: 0,
        });

        // submit the answer
        const questionResponse = await getQuestionData(questionBody);
        if (questionResponse?.data?.Metadata?.message === 'complete') {
          await this.setState({
            currentQuestions: [],
            loading: false,
            progress: 100,
            surveyCompleted: true,
            surveyCompleteMessage: questionResponse?.data?.Data?.text || '',
            currentQuestionGroup: 0,
          });
          // Exco Short Fix
          if (joinCode === 'VYYFUE8X') {
            newUrl = `${baseUrl}?j=XOTUDQHU`;
          } else if (joinCode === 'XOTUDQHU') {
            newUrl = `${baseUrl}?j=QN0K1DZU`;
          } else if (joinCode === 'QN0K1DZU') {
            newUrl = `${baseUrl}?j=EF7A195R`;
          } else if (joinCode === 'EF7A195R') {
            newUrl = `${baseUrl}?j=YGWHIJJ2`;
          }
          if (newUrl) {
            setTimeout(() => {
              window.location.href = newUrl;
            }, 1700);
          }
        }
      } catch (error) {
        let currentQuestions = '';
        if (error.response) {
          currentQuestions = [
            {
              questionID: 0,
              questionType: 'ERROR_TYPE',
              questionText: error.response,
              answer: true,
              answerList: ['YES', 'NO', 'STOP'],
              questionLevel: '1',
            },
          ];
        } else {
          currentQuestions = [
            {
              questionID: 0,
              questionType: 'ERROR_TYPE',
              questionText: 'Technical Error! Try again later',
              answer: true,
              answerList: ['YES', 'NO', 'STOP'],
              questionLevel: '1',
            },
          ];
        }
        this.setState({ currentQuestions });
      } finally {
        this.setState({ surveyAnswer: [] });
      }
    } else {
      this.setState({ errorMessage: 'Please give an answer', showError: true });
      setTimeout(() => {
        this.setState({ errorMessage: '', showError: false });
      }, 5000);
    }
  }

  /** false
   * Fetches the first question on 2 conditions
   * first being if there is no data on the particular survey in local storage
   * Second if the same survey is n local storage but the last object is the end message
   * @return {Promise<void>}
   */
  fetchFirstQuestion = async () => {
    this.setState({ loading: true });
    const { joinCode, commId, userMetadata } = this.props;
    if (!joinCode || !commId) {
      this.setState({ isInvalid: true });
      return;
    }
    this.setState({ isInvalid: false });
    try {
      const questionBody = {
        commId,
        surveyJoinCode: joinCode,
        responseList: [
          {
            questionID: 0,
            responseText: {
              value: joinCode,
              text: joinCode,
            },
            questionLevel: '1',
          },
        ],
        surveyProgressMetadata: userMetadata,
      };
      this.setState({ loading: true });
      // loading here
      await getQuestionData(questionBody);
      // there is a question type called questionError
    } catch (error) {
      let currentQuestions = '';
      if (error.response) {
        currentQuestions = [
          {
            questionID: 0,
            questionType: 'ERROR_TYPE',
            questionText: error.response,
            answer: true,
            answerList: ['YES', 'NO', 'STOP'],
            questionLevel: '1',
          },
        ];
        this.setState({
          errorMessage:
            getNestedObjectSafely(error, 'response', 'data', 'message') ||
            'Technical error! Try again later.',
          showError: true,
        });
        setTimeout(() => {
          this.setState({ errorMessage: '', showError: false });
        }, 10000);
      } else {
        currentQuestions = [
          {
            questionID: 0,
            questionType: 'ERROR_TYPE',
            questionText: 'Technical error! Try again later.',
            answer: true,
            answerList: ['YES', 'NO', 'STOP'],
            questionLevel: '1',
          },
        ];
      }
      this.setState({ currentQuestions });
    }
  };

  scrollToMessageBottom = () => {
    const { messageList } = this;
    if (messageList && messageList.current) {
      messageList.current.scrollTop =
        messageList.current.scrollHeight - messageList.current.clientHeight;
    }
  };

  async onFetchLogo(accountId) {
    try {
      const companyLogo = await fetchBusinessData(accountId);
      this.setState({ accountLogo: companyLogo.data.Data.logo });
    } catch (exception) {
      //console.log('fetch company logo:', exception);
    }
  }

  async calculateProgress() {
    const { surveyAnswer, allQuestions } = this.state;
    let numOfQuestions = allQuestions?.webQuestionList?.length;
    if (numOfQuestions > 0) {
      let hasLast = false;
      if (allQuestions.webQuestionList.find((question) => question.lastQuestion === true) != null)
        hasLast = true;

      let totalNumber = allQuestions.webQuestionList[0].totalQuestions;
      const firstNumber = allQuestions.webQuestionList[0].questionNumber;
      const lastNumber = allQuestions.webQuestionList[numOfQuestions - 1].questionNumber;
      if (hasLast) {
        totalNumber = lastNumber;
      }
      const percentageChunk = ((numOfQuestions / totalNumber) * 100) / numOfQuestions;
      const lastPercentage = ((firstNumber - 1) / totalNumber) * 100;
      let answeredQuestions = 0;

      surveyAnswer.forEach((answer) => {
        if (
          answer?.questionType !== 'MESSAGE_END' &&
          answer?.questionType !== 'ERROR_TYPE' &&
          answer?.questionType !== 'END_MESSAGE' &&
          answer?.questionType !== 'INTRO_MESSAGE_MESSAGE' &&
          answer?.questionType !== 'MESSAGE'
        ) {
          if (answer?.responseText?.text?.length > 0) {
            answeredQuestions += 1;
          }
        } else {
          answeredQuestions += 1;
        }
      });
      // if (allQuestions.webQuestionList.find(question => question.lastQuestion === true) != null && answeredQuestions === Number(numOfQuestions))
      //     this.setState({progress: 100});
      // else
      this.setState({
        progress: Math.round(lastPercentage + answeredQuestions * percentageChunk),
      });
    }
  }

  async triggerNextQuestion(data) {
    const { accountLogo, loading } = this.state;
    const { commId, joinCode } = this.props;
    const decodedData = decodeURI(data);
    const nextQuestions = JSON.parse(decodedData);
    console.log('next questions', nextQuestions);
    // check if i should care about this question
    // if i am not in a loading state do not update me
    if (nextQuestions.to === commId && loading === true) {
      // if the end trigger end page
      if (
        getNestedObjectSafely(nextQuestions, 'webQuestionList') &&
        (nextQuestions.webQuestionList[0].questionType === 'MESSAGE_END' ||
          nextQuestions.webQuestionList[0].questionType === 'END_MESSAGE')
      ) {
        await this.setState({
          currentQuestions: nextQuestions.webQuestionList[0],
          loading: false,
          accountId: nextQuestions.accountID,
          progress: nextQuestions.progress,
          surveyTitle: nextQuestions.surveyTitle,
          surveyCompleted: true,
          allQuestions: nextQuestions,
        });
        return;
      }
      // attempt to create question groups
      const finalGroup = [];
      let currentGroup = [];

      const surveyAnswer = [];
      if (nextQuestions.webQuestionList && nextQuestions.webQuestionList.length > 0) {
        // some shady code to create matrix questions
        nextQuestions.webQuestionList.forEach((question) => {
          // create empty answer list
          surveyAnswer.push({
            responseText: { value: '', text: '' },
            isFinal: question.lastQuestion,
            required: question.required,
            questionID: question.questionID,
            questionLevel: question.questionLevel,
            questionType: question.questionType,
          });
          if (
            question.questionType === 'OPEN_ENDED_RANGE_0_10' ||
            question.questionType === 'MESSAGE'
          ) {
            currentGroup.push(question);
          } else if (question.questionType === 'OPEN_ENDED' && currentGroup.length > 1) {
            currentGroup.push(question);
            finalGroup.push(currentGroup);
            currentGroup = [];
          } else {
            currentGroup.push(question);
            finalGroup.push(currentGroup);
            currentGroup = [];
          }
        });
        // what if all messages or open ended are the last questions
        if (currentGroup.length > 0) finalGroup.push(currentGroup);
        let ongoingGroups = [];
        const mergedGroups = [];
        // merge incomplete groups
        finalGroup.forEach((group) => {
          // const messageNumber = group.filter((question) => question.questionType === 'MESSAGE').length || 0;
          const rangeNumber =
            group.filter((question) => question.questionType === 'OPEN_ENDED_RANGE_0_10').length ||
            0;
          const openNumber =
            group.filter((question) => question.questionType === 'OPEN_ENDED').length || 0;
          if (rangeNumber > 1 && openNumber === 1) {
            if (ongoingGroups.length > 0) {
              mergedGroups.push(ongoingGroups);
            }
            ongoingGroups = [];
            mergedGroups.push(group);
          } else {
            ongoingGroups = [...ongoingGroups, ...group];
          }
        });
        if (ongoingGroups.length > 0) {
          mergedGroups.push(ongoingGroups);
        }

        await this.setState(
          {
            currentQuestions: mergedGroups[0],
            loading: false,
            accountId: nextQuestions.accountID,
            progress: nextQuestions.webQuestionList[0].progress || 0,
            surveyTitle: nextQuestions.surveyTitle,
            surveyAnswer,
            questionGroups: mergedGroups,
            allQuestions: nextQuestions,
          },
          () => {
            this.calculateProgress();
          },
        );
      }
      if (accountLogo === null) {
        await this.onFetchLogo(nextQuestions.accountID);
      }
    }
  }

  render() {
    const {
      currentQuestions,
      surveyAnswer,
      isCaptchaValid,
      loading,
      progress,
      captchaLoaded,
      errorMessage,
      showError,
      isInvalid,
      surveyCompleted,
      accountLogo,
      surveyTitle,
      surveyCompleteMessage,
    } = this.state;

    // ESco Temporary Fix
    const { joinCode } = this.props;
    const excoJoinCodes = [' VYYFUE8X ', ' XOTUDQHU ', ' QN0K1DZU ', ' EF7A195R ', ' YGWHIJJ2 '];
    if (
      (joinCode === 'VYYFUE8X' ||
        joinCode === 'XOTUDQHU' ||
        joinCode === 'QN0K1DZU' ||
        joinCode === 'EF7A195R') &&
      surveyCompleted
    ) {
      this.setState({
        surveyCompleteMessage: 'Hold on as we direct you to the next section of the survey',
      });
    }
    return (
      <section
        //temporary fix for KQ cargo survey
        className={`web-form-container ${
          this.props.joinCode === ('ZNTR6S8Y' || '7HBR1G') ? 'kq-font' : 'web-form-container-font'
        }`}
      >
        <div>
          <div className="logo-section">
            {accountLogo ? (
              <img
                onError={() => this.setState({ accountLogo: null })}
                className="company-logo"
                alt="company logo"
                src={accountLogo}
              />
            ) : (
              <div className="company-logo">&nbsp;</div>
            )}
          </div>
          {isInvalid && <Navigate to="/404" />}
          {surveyCompleted && <SurveySubmitted message={surveyCompleteMessage} />}
          {isCaptchaValid && !isInvalid && !surveyCompleted && (
            <>
              <section className="web-view">
                {loading && (
                  <div
                    style={{
                      zIndex: '999999',
                      background: 'white',
                    }}
                  >
                    <div className="loader-container">
                      <Loader
                        // css={override}
                        size={50}
                        color="#2196f3"
                        loading={loading && captchaLoaded}
                      />
                    </div>
                  </div>
                )}

                {!loading && (
                  <div className="web-container content-container">
                    {!this.props.isAnonymousUser && (
                      <span className="x-small align-self-start" style={{ marginRight: '0.5rem' }}>
                        You are completing this survey as {this.props.commId}
                      </span>
                    )}
                    {/* KQ don't want survey titles when the survey is embedded, Show title when exco JoinCode is used */}
                    {document.referrer !== '' && excoJoinCodes.includes(joinCode) ? null : (
                      <h2 className="survey-title">
                        <strong>
                          {surveyTitle.toLowerCase() === 'survey error' ? '' : surveyTitle}
                        </strong>
                      </h2>
                    )}
                    <div id="webFormProgress">
                      <div id="webFormBar" style={{ width: `${progress || 0}%` }}>
                        {progress || 0}%
                      </div>
                    </div>
                    {currentQuestions.map((question) => {
                      let questionValue = '';
                      let questionResponseText = {
                        value: '',
                        text: '',
                      };
                      const questionFound = surveyAnswer.find(
                        (answer) => answer.questionID === question.questionID,
                      );
                      if (questionFound != null) {
                        questionValue = questionFound.responseText.value;
                        questionResponseText = questionFound.responseText;
                      }

                      if (!question.questionText.includes('<strong>')) {
                        // replace asterisk with red color
                        if (question.required === true) {
                          if (question.questionText.includes('*')) {
                            question.questionText = question.questionText.replace(
                              '*',
                              '<span style="color: red; \n' +
                                '  font-size: 1.5em;margin-right: 0.2rem">*</span>',
                            );
                          } else {
                            question.questionText = `<span style="color: red;font-size: 1.5em;margin-right: 0.2rem">*</span>${question.questionText}`;
                          }
                        }
                        question.questionText = `<strong>${question.questionText}</strong>`;
                      }
                      return (
                        <React.Fragment key={question.questionID}>
                          {/* If the question loaded is valid, we broadcast a message to any parent window */}
                          {/* TODO: Since this is sent weith every question loaded, need to check if it causes unintented effects */}
                          {question.questionType !== 'QuestionError' && document.referrer
                            ? window.parent.postMessage(
                                {
                                  ok: true,
                                  message: 'Webform is valid',
                                  code: 'WEBFORM_VALID',
                                  type: 'webform:onvalid',
                                },
                                document.referrer,
                              )
                            : null}
                          {question.questionType === 'OPEN_ENDED_NPS_0_10' ||
                          question.questionType === 'NPS_CHOICE' ? (
                            <NpsQuestion
                              question={question}
                              value={questionValue}
                              onChange={this.onQuestionValueChanged}
                            />
                          ) : question.questionType === 'OPEN_ENDED_NPS' ||
                            question.questionType === 'OPEN_ENDED' ? (
                            <OpenEnded
                              question={question}
                              value={questionValue}
                              onChange={this.onQuestionValueChanged}
                            />
                          ) : question.questionType === 'OPEN_ENDED_RANGE_0_10' ||
                            question.questionType === 'RANGE_0_10' ? (
                            <RangeQuestion
                              question={question}
                              value={questionValue}
                              onChange={this.onQuestionValueChanged}
                            />
                          ) : question.questionType === 'MULTIPLE_CHOICE_SINGLE_SELECT' ||
                            question.questionType === 'INTRO_MESSAGE_MULTIPLE_CHOICE' ? (
                            <SingleSelect
                              question={question}
                              value={questionValue}
                              onChange={this.onQuestionValueChanged}
                            />
                          ) : question.questionType === 'CUSTOMER_SATISFACTION_SCORE' ? (
                            <CSAT
                              question={question}
                              value={questionValue}
                              onChange={this.onQuestionValueChanged}
                            />
                          ) : question.questionType === 'OPEN_ENDED_INTEGER' ? (
                            <IntegerChoice
                              question={question}
                              value={questionValue}
                              onChange={this.onQuestionValueChanged}
                            />
                          ) : question.questionType === 'RESOLUTION_SCORE' ? (
                            <ResolutionScore
                              question={question}
                              value={questionValue}
                              onChange={this.onQuestionValueChanged}
                            />
                          ) : question.questionType === 'MULTIPLE_CHOICE_MULTIPLE_SELECT' ? (
                            <MultipleSelect
                              question={question}
                              questionValue={questionResponseText}
                              onChange={this.onQuestionValueChanged}
                            />
                          ) : question.questionType === 'MESSAGE_END' ||
                            question.questionType === 'ERROR_TYPE' ||
                            question.questionType === 'END_MESSAGE' ||
                            question.questionType === 'INTRO_MESSAGE_MESSAGE' ||
                            question.questionType === 'MESSAGE' ||
                            question.questionType === 'QuestionError' ? (
                            <FallBack question={question} />
                          ) : (
                            <OpenEnded
                              question={question}
                              value={questionValue}
                              onChange={this.onQuestionValueChanged}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                    {currentQuestions[0].questionType !== 'QuestionError' && (
                      <button
                        className="submit-button"
                        onClick={this.onSubmitFeedback}
                        type="button"
                      >
                        {/* {currentQuestions.find((question) => question?.lastQuestion === true)
                          ? 'Submit'
                          : 'Next'} */}
                        {/* Short fix for Exco */}
                        {(joinCode === 'VYYFUE8X' ||
                          joinCode === 'XOTUDQHU' ||
                          joinCode === 'QN0K1DZU' ||
                          joinCode === 'EF7A195R') &&
                        currentQuestions.find((question) => question?.lastQuestion === true)
                          ? 'Proceed to Next Section'
                          : currentQuestions.find((question) => question?.lastQuestion === true)
                          ? 'Submit'
                          : 'Next'}
                      </button>
                    )}
                  </div>
                )}
              </section>
            </>
          )}
          {showError && <div className="footer">{errorMessage}</div>}
          {!isCaptchaValid && !isInvalid && !surveyCompleted && (
            <div className="content-container captcha">
              <div className="loader-container">
                <Loader
                  // css={override}
                  size={50}
                  color="#2196f3"
                  loading={!captchaLoaded}
                />
              </div>
              {captchaLoaded && <h2> Complete the captcha below to access this survey</h2>}
              <Recaptcha
                onloadCallback={this.onCaptchaLoaded}
                sitekey="6LdQR9YUAAAAAM9u_d34yE40lG0e8WqYrY8M4xhw"
                render="explicit"
                verifyCallback={this.onCaptchaVerify}
              />
              <div className="header">
                <a href="https://www.ajua.com">
                  <img src={logo} alt="Ajua Logo" className="logo-header" />
                  <span>Powered by Ajua</span>
                </a>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default WebForm;
